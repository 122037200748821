import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Iframe from 'react-iframe'
import Header from '../components/header'
import Footer from '../components/footer'
import Layout from "../components/layout"
import TileMenuItem from '../components/tile-menu-item'
import ShopItem from '../components/shop-item'
// import ProjectPreview from '../components/project-preview'
import shopDummyItems from '../../data/mock-shop-items'

class ShopPage extends React.Component {

  getEmbeddedShop() {
    return (
      <Iframe url="http://www.redbubble.com/people/PONNAPA/external-portfolio?count=5"
        width="100%"
        height="500px"
        id="myId"
        className="myClassname"
        display="initial"
        frameBorder="0"
        scrolling="no"
        position="relative"/>
    )
  }

  componentDidMount() {
    // <script id="rb-xzfcxvzx" type="text/javascript">new RBExternalPortfolio('www.redbubble.com', 'PONNAPA', 5, 3).renderIframe();</script>
    console.log('RBExternalPortfolio', window.RBExternalPortfolio);
  }

  render() {
    const siteMetadata = get(this, 'props.data.site.siteMetadata')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')
    const shopItems = shopDummyItems

    return (
      <div>
        <Helmet>
          <title>{`SHOP | ${siteMetadata.title}`}</title>
          <meta name="description" content={siteMetadata.description} />
          <meta name="keywords" content={siteMetadata.keywords} />
          <meta name="author" content={siteMetadata.author} />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          {/*
          <meta property="og:url" content={ogUrl} />
          */}
          <meta property="og:type" content="website" />
          <meta property="og:title" content={siteMetadata.title} />
          <meta property="og:description" content={siteMetadata.description} />
          <meta property="og:image" content={siteMetadata.image} />
        </Helmet>

        <Header data={author.node} />
        <div className="wrapper">
          {/*
            <h2 className="section-headline">Wall</h2>
          */}
          <h3>Shop</h3>
          {/*
          <ul className="article-list">
            {shopItems.map(item => (<ShopItem item={item} key={item.title} />))}
          </ul>

          <br />
          <h3>More Products On...</h3>
          */}
          <ul className="shop-list">
            <li>
              <TileMenuItem
                title={<div style={{ textAlign: 'center'}}>
                    <img src="/images/logo-artsy-black.png"  
                    style={{ maxHeight: '75px', width: 'auto', margin: 'auto' }} />
                  </div>}
                image=""
                linkUrl={'https://www.artsy.net/artist/ponnapa-prakkamakul'}
                target="_blank" />

            </li>
            <li>
              <TileMenuItem
                title={<div style={{ textAlign: 'center'}}>
                    <img src="/images/logo-opensea.png" 
                    style={{ maxHeight: '75px', width: 'auto', margin: 'auto' }} />
                  </div>}
                image=""
                linkUrl={'https://opensea.io/ponnapa'}
                target="_blank" />
              {/*
              <ProjectPreview project={node} linkPrefix="wall" />
              */}
            </li>
          </ul>
        </div>
        {/*
        https://www.saatchiart.com/account/profile/1706461
        */}
        <Footer data={author.node} />
      </div>
    )
  }
}

export default ShopPage

export const pageQuery = graphql`
  query ShopPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
        description
        image
        keywords
        author
      }
    }

    allContentfulPerson(filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }) {
      edges {
        node {
          name
          instagram
          email
        }
      }
    }
  }
`
