import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { addCloudinaryTransformPreset } from '../lib/util'

import styles from './tile-menu.module.css'

export default ({ title, image, linkUrl, onClick, target }) => (
  <div className={styles.tileMenuItem}>
    <a href={`${linkUrl}`} 
      className="hover-fade" 
      target={target ? target : '_self'}
    onClick={(e) => {
      if(onClick) {
        e.preventDefault()
        return onClick(e)
      }
    }}>
      {image &&
        <img alt="" src={image} />
      }
      
      <h3 className={styles.tileMenuTitle}>
        {title}
      </h3>
    </a>
  </div>
)
